import { ChatAltIcon, EyeOffIcon, PaperClipIcon } from "@heroicons/react/solid"
import { NavLink } from "react-router-dom"
import { format  } from 'date-fns'

export const DashboardPost = ({message}) => {
  
    return (
        <>
           <div className={"relative group border hover:border-gray-400 bg-white hover:bg-gray-50 p-6  text-left pb-20 shadow-md rounded-md "  } > 
                      <div >
                     
                        <h3 className="text-base font-medium">
                          
                          
                            {message.name}
                           
                        </h3>
                  
                        <div
                        className="mt-2 text-sm text-gray-500 space-y-4  line-clamp-6"
                        dangerouslySetInnerHTML={{ __html: message.short_text }}
                      />
 
                      </div>
                      <div className="mt-6 flex justify-between space-x-8 absolute w-full bottom-4 left-0 px-6">
                        <div className="flex space-x-6">
                        {message.visible === '0' && <>
                            <span className="inline-flex items-center text-sm">
                              <EyeOffIcon className="text-red-600 w-5" />
                            </span>
                          </>}
                          {message?.files?.length > 0 && 
                          
                          <span className="inline-flex items-center text-sm">
                            <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                              <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                              <span className="font-medium text-gray-900">{message?.files?.length}</span>
                              <span className="sr-only">přílohy</span>
                            </button>
                          </span>
                        }
                          
                          
                          {message.comment_count > 0 && 
                          
                            <span className="inline-flex items-center text-sm">
                              <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                                <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                                <span className="font-medium text-gray-900">{message.comment_count}</span>
                                <span className="sr-only">komentáře</span>
                              </button>
                            </span>
                          }
                           
                        </div>
                        <div className="flex space-x-3">
                          <div className="flex-shrink-0">
                            {message?.author_img ? (
                              <img className="h-10 w-10 rounded-full" src={message.author_img} alt="" />
                            ) : (
                              ""
                            )
                            }
                          </div>
                          <div className="min-w-0 flex-1 grid">
                            <p className="text-sm font-medium  text-gray-900 truncate">
                              {message?.author}
                            </p>
                            <p className="text-sm text-gray-500 text-right">
                                <time dateTime={message.datetime}>{format(new Date(message.date), 'd. M. y')}</time>
                               
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center flex">
                             
                          </div>
                        </div>
                      </div> 
                      {message.visible === '0' && <>
                            <span className="absolute inset-0 bg-gray-100 bg-opacity-50 rounded-md">
                               
                            </span>
                    </>}
                      <NavLink to={'/nastenka/prispevky/' + message.id} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                      </NavLink>
                    </div>
        </>
    )
}