import React, { useEffect, useState } from 'react'; 

import Files from './Files';
import Node from './Node';

const Branch = ({ item, level, branch, activeBranch, setActiveBranch, parents, activeFolder, setActiveFolder, files, folderSettings, updateFolderSettings, setSearchResults }) => {
	const [selected, setSelected] = useState(item.selected ?? false);
	const parent = parents[activeFolder];
     
	const hasChildren = item.children && item.children.length !== 0;
	 
	const renderBranches = () => {
         
		if (hasChildren) {
			const newLevel = level + 1;
            
			return item.children.map((child) => {
				return <Branch key={child.id} item={child} branch={branch} level={newLevel} activeBranch={activeBranch} setActiveBranch={setActiveBranch}  parents={parents} activeFolder={activeFolder} setActiveFolder={setActiveFolder} files={files}
					updateFolderSettings={updateFolderSettings} 
					folderSettings={folderSettings}
					setSearchResults={setSearchResults}
				/>
			});
		}

		return null;
	};
     
    useEffect(() => {   
		if(activeFolder) {
			if(selected && branch !== activeBranch) {
				 setSelected(false);
			}  
			if((!parent?.includes(item.id) && activeFolder !== item.id) && branch === activeBranch) {
				setSelected(false); 
			} 
			if(activeFolder === item.id) {
				setSelected(true);
			}
		}
    } 
          // eslint-disable-next-line
    , [selected, activeBranch, activeFolder]);

	const toggleSelected = () => {
		if(activeFolder===item.id) {
			setActiveFolder(false)
			setSelected(false);
		} else {
			setActiveFolder(item.id);

		}
		setSearchResults({"search":[]});
        setActiveBranch(branch);
        //setSelected(item.id);
		 
        
	};

	return (
		<>
			<Node
				item={item}
				selected={selected}
				hasChildren={hasChildren}
				level={level}
				onToggle={toggleSelected}
				files={files}
				activeFolder={activeFolder}
			/>
			{selected && renderBranches()}
			{selected && item.id === activeFolder && <div className="px-2  lg:hidden overflow-hidden pb-5 bg-gray-100" ><Files files={files} activeFolder={activeFolder} updateFolderSettings={updateFolderSettings} setSearchResults={setSearchResults}
                        folderSettings={folderSettings}   /></div>}
		</>
	);
};

export default Branch;