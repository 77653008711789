import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid';
import React from 'react';


const Node = ({ item, selected, level, onToggle, files, activeFolder }) => {
	 
	return (
		<>
		<div onClick={onToggle} className={"relative flex py-2  cursor-pointer hover:bg-gray-100  overflow-hidden" + (selected ? " text-green-600  " : "text-gray-600")}>
			<div style={{ paddingLeft: `${level * 15}px`}}  className="flex space-x-3 mx-3  items-center">
				{selected ? ( 
					<>
					<div className="w-5  flex items-center">
					{item.children.length > 0 && (
						<ChevronDownIcon className="w-5" />
					)} 
					</div>
						<svg className="w-5 h-5  " fill="currentColor" viewBox="0 -57 511.99973 511"  xmlns="http://www.w3.org/2000/svg">
							<path d="m506.039062 180.988281c-7.78125-12.546875-21.53125-20.046875-36.78125-20.046875h-339.5625c-16.832031 0-32.140624 9.488282-39.011718 24.179688l-89.8125 188.308594c3.390625 13.789062 16.269531 24.089843 31.609375 24.089843h361.269531c15.445312 0 29.5625-8.734375 36.460938-22.554687l77.628906-155.59375c6.128906-12.3125 5.449218-26.660156-1.800782-38.382813zm0 0"/><path d="m72.402344 156.15625c6.863281-14.6875 22.175781-24.179688 39.011718-24.179688h319.753907v-40.898437c0-16.859375-14.222657-30.578125-31.703125-30.578125h-186.445313c-.273437 0-.460937-.070312-.53125-.121094l-33.371093-46.660156c-5.910157-8.277344-15.671876-13.21875-26.101563-13.21875h-121.304687c-17.488282 0-31.710938 13.71875-31.710938 30.578125v276.875zm0 0"/>
						</svg>
					</>
					) : (  
						<>
						<div className="w-5 flex items-center">
						{item.children.length > 0 && (
							<ChevronRightIcon className="w-5 text-gray-400" />
						)} 
						</div>
						<svg  className="w-5 h-5   text-gray-400" fill="currentColor"   xmlns="http://www.w3.org/2000/svg" 
							viewBox="0 0 408 408" >
						
								<path d="M372,88.661H206.32l-33-39.24c-0.985-1.184-2.461-1.848-4-1.8H36c-19.956,0.198-36.023,16.443-36,36.4v240
									c-0.001,19.941,16.06,36.163,36,36.36h336c19.94-0.197,36.001-16.419,36-36.36v-199C408.001,105.08,391.94,88.859,372,88.661z"/>
						
						</svg> 
						</>
				)}

				
				<span className="truncate">{item.name}</span>
			</div>
			<span className={"absolute right-2 top-2 mt-0.5 inline-block py-0.5 px-2 text-xs rounded-full  group-hover:bg-gray-400 " + (selected ? " bg-green-100  " : "bg-gray-200")}>
				{item?.document_count}
			</span>
		</div>
		
		</>
	);
};

export default Node;