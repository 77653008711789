import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error } from "../form/form";
import moment from "moment";
import "moment/locale/cs";
import FileUpload from "../fileUpload";
import { axiosProtected } from "../../client";
import { EyeOffIcon } from "@heroicons/react/solid";
import { DashboardApprove } from "./DashboardApprove";
import { CommentApprove } from "./CommentApprove";
import { CommentDelete } from "./CommentDelete";
import { FileList } from "../layout/FileList";
import { File } from "../facility/documents/Files";

export const DashboardDetail = (props) => {
    let { prispevekId } = useParams();
    const setModal = props.setModal;
    const [uploadId, setId] = useState();
    const [submittedData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isOn, setIsOn] = useState(false);
    const building = props.building;
    const user = props.userData;
    const setBuilding = props.setBuilding;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    const [item, setItem] = useState(
        building.messageboard_all.find((x) => x.id === prispevekId)
    );

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ ...submittedData });
        }
    }, [isSubmitSuccessful, submittedData, reset]);

    const onSubmit = async (data) => {
        setLoading(true);
        const response = await axiosProtected()
            .post("units/messageboard_comment/", data)
            .then((res) => {
                setBuilding("", res.data);
                reset(response);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(
        () => {
            const found = building.messageboard_all.find(
                (x) => x.id === prispevekId
            );
            setItem(found);
        },
        // eslint-disable-next-line
        [prispevekId]
    );

    useEffect(() => {
        if (!uploadId && isOn) {
            const id =
                "upload_" +
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
            setId(id);
            setValue("uploadId", id);
        }
        // eslint-disable-next-line
    }, [uploadId, isOn]);

    return (
        <>
            {item ? (
                <>
                    <div className="w-full ">
                        <h3 className="text-lg text-gray-800 md:text-xl mx-auto  mb-4">
                            {item?.name}
                        </h3>
                        {item.visible === "0" && (
                            <>
                                <div className="grid lg:flex  lg:justify-between  p-3 rounded-md bg-gray-200 border border-gray-300">
                                    <span className=" text-sm  space-x-2 flex items-center text-gray-700">
                                        <EyeOffIcon className=" w-5" />{" "}
                                        <span>příspěvek čeká na schválení</span>
                                    </span>
                                    <DashboardApprove
                                        building={building}
                                        item={item}
                                        setBuilding={setBuilding}
                                    />
                                </div>
                            </>
                        )}
                        <div className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2">
                            <div className="flow-root ">
                                <ul className=" divide-y divide-gray-200 ">
                                    <li>
                                        <div className="relative py-5">
                                            <div className="relative flex items-start space-x-3">
                                                <div className="relative">
                                                    {item?.author_img ? (
                                                        <img
                                                            className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                            src={
                                                                item?.author_img
                                                            }
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <>
                                                            <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                <svg
                                                                    className="h-7 w-7 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="min-w-0 flex-1">
                                                    <div>
                                                        <div className="text-sm">
                                                            <span className="font-medium text-gray-900">
                                                                {item?.author}
                                                            </span>
                                                        </div>
                                                        <p className="mt-0.5 text-sm text-gray-500">
                                                            {moment(
                                                                item?.date
                                                            ).format("LLL")}
                                                        </p>
                                                    </div>

                                                    <div className="mt-2 text-sm text-gray-700">
                                                        <div
                                                            className="whitespace-pre-line"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.text,
                                                            }}
                                                        ></div>

                                                        {item?.documents &&
                                                            item?.documents
                                                                ?.length >
                                                                0 && (
                                                                <div className="my-2 text-sm border-t pt-3">
                                                                    {item?.documents.map(
                                                                        (
                                                                            file,
                                                                            itemIdx
                                                                        ) => {
                                                                            return (
                                                                                <File
                                                                                    key={
                                                                                        itemIdx
                                                                                    }
                                                                                    file={
                                                                                        file
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            )}

                                                        {item?.images &&
                                                            item?.images
                                                                ?.length >
                                                                0 && (
                                                                <FileList
                                                                    files={
                                                                        item?.images
                                                                    }
                                                                    setModalContent={
                                                                        props.setModalContent
                                                                    }
                                                                    setModalIsOpen={
                                                                        props.setModalIsOpen
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    {item?.comments?.map((comment, index) => {
                                        return (
                                            <li key={index}>
                                                <div
                                                    className={
                                                        "relative py-5 " +
                                                        (comment.visible === "2"
                                                            ? " opacity-50"
                                                            : "")
                                                    }
                                                >
                                                    <div className="relative flex items-start space-x-3">
                                                        <div className="relative">
                                                            {comment?.img ? (
                                                                <img
                                                                    className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                                    src={
                                                                        comment.img
                                                                    }
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                        <svg
                                                                            className="h-7 w-7 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 20 20"
                                                                            fill="currentColor"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                                clipRule="evenodd"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="min-w-0 flex-1 w-full">
                                                            <div className="w-full flex justify-between">
                                                                <div>
                                                                    <div className="text-sm">
                                                                        <span className="font-medium text-gray-900">
                                                                            {comment.vlozil
                                                                                ? comment.vlozil
                                                                                : comment.nickname}
                                                                        </span>
                                                                    </div>
                                                                    <p className="mt-0.5 text-sm text-gray-500">
                                                                        {moment(
                                                                            comment.inserted
                                                                        ).format(
                                                                            "LLL"
                                                                        )}
                                                                    </p>
                                                                </div>
                                                                <div className="w-6">
                                                                    <CommentDelete
                                                                        building={
                                                                            building
                                                                        }
                                                                        item={
                                                                            comment
                                                                        }
                                                                        setBuilding={
                                                                            setBuilding
                                                                        }
                                                                        dialog={
                                                                            props.dialog
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 text-sm text-gray-700">
                                                                <div
                                                                    className="whitespace-pre-line"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: comment.message,
                                                                    }}
                                                                ></div>
                                                                {comment?.documents &&
                                                                    comment
                                                                        ?.documents
                                                                        ?.length >
                                                                        0 && (
                                                                        <div className="my-2 text-sm border-t pt-3">
                                                                            {comment?.documents.map(
                                                                                (
                                                                                    file,
                                                                                    itemIdx
                                                                                ) => {
                                                                                    return (
                                                                                        <File
                                                                                            key={
                                                                                                itemIdx
                                                                                            }
                                                                                            file={
                                                                                                file
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                {comment?.images &&
                                                                    comment
                                                                        ?.images
                                                                        ?.length >
                                                                        0 && (
                                                                        <FileList
                                                                            files={
                                                                                comment?.images
                                                                            }
                                                                            setModalContent={
                                                                                props.setModalContent
                                                                            }
                                                                            setModalIsOpen={
                                                                                props.setModalIsOpen
                                                                            }
                                                                        />
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {comment.visible === "2" && (
                                                    <>
                                                        <div className="grid lg:flex  lg:justify-between  p-3 py-1 rounded-md bg-gray-200 border border-gray-300">
                                                            <span className=" text-sm  space-x-2 flex items-center text-gray-700">
                                                                <EyeOffIcon className=" w-5" />{" "}
                                                                <span>
                                                                    komentář
                                                                    čeká na
                                                                    schválení
                                                                </span>
                                                            </span>
                                                            <CommentApprove
                                                                building={
                                                                    building
                                                                }
                                                                item={comment}
                                                                setBuilding={
                                                                    setBuilding
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </li>
                                        );
                                    })}
                                    {item.visible !== "0" && (
                                        <>
                                            <li>
                                                <div className="relative py-5">
                                                    <div className="relative flex items-start space-x-3">
                                                        <div className="relative">
                                                            {user?.img ? (
                                                                <img
                                                                    className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                                                                    src={
                                                                        user.img
                                                                    }
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div className="h-10 w-10 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                                                                        <svg
                                                                            className="h-7 w-7 text-gray-500"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 20 20"
                                                                            fill="currentColor"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                                                                clipRule="evenodd"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="min-w-0 flex-1">
                                                            <div>
                                                                <form
                                                                    onSubmit={handleSubmit(
                                                                        onSubmit
                                                                    )}
                                                                >
                                                                    {item && (
                                                                        <input
                                                                            type="hidden"
                                                                            {...register(
                                                                                "item_id"
                                                                            )}
                                                                            name="item_id"
                                                                            defaultValue={
                                                                                item?.id
                                                                            }
                                                                        />
                                                                    )}
                                                                    <input
                                                                        type="hidden"
                                                                        {...register(
                                                                            "unit_id"
                                                                        )}
                                                                        name="unit_id"
                                                                        defaultValue={
                                                                            building
                                                                                ?.unit
                                                                                ?.units_id
                                                                        }
                                                                    />
                                                                    <div className="relative max-w-xl group">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                isOn
                                                                                    ? setIsOn(
                                                                                          false
                                                                                      )
                                                                                    : setIsOn(
                                                                                          true
                                                                                      )
                                                                            }
                                                                            className={
                                                                                "p-1  border rounded-full absolute top-2 right-2 focus:outline-none " +
                                                                                (isOn
                                                                                    ? "bg-green-500 text-white border-green-600 "
                                                                                    : "bg-gray-200 text-gray-600 border-gray-400 ")
                                                                            }
                                                                            title="Přidat přílohu"
                                                                        >
                                                                            <svg
                                                                                className="w-5  "
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                stroke="currentColor"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <textarea
                                                                            {...register(
                                                                                "message",
                                                                                {
                                                                                    required: true,
                                                                                }
                                                                            )}
                                                                            className="border-1 bg-gray-50 border-gray-300 flex-grow  h-24 px-3 py-1 pr-6 w-full rounded-lg focus:border-gray-300 focus:placeholder-gray-200 focus:ring-2 focus:outline-none  focus:ring-green-500"
                                                                            name="message"
                                                                            placeholder="Napište komentář"
                                                                        ></textarea>
                                                                        {errors.name && (
                                                                            <Error text="Komentář nelze odeslat prázdný" />
                                                                        )}
                                                                        {isOn && (
                                                                            <>
                                                                                <FileUpload
                                                                                    uploadId={
                                                                                        uploadId
                                                                                    }
                                                                                    setModal={
                                                                                        setModal
                                                                                    }
                                                                                    module={
                                                                                        "messageboard"
                                                                                    }
                                                                                />
                                                                                <input
                                                                                    type="hidden"
                                                                                    {...register(
                                                                                        "uploadId"
                                                                                    )}
                                                                                    name="uploadId"
                                                                                    defaultValue={
                                                                                        uploadId
                                                                                    }
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        {loading ? (
                                                                            <button
                                                                                disabled
                                                                                className="bg-gray-200 text-gray-800 font-bold py-2 px-10 w-full lg:w-auto rounded cursor-default"
                                                                            >
                                                                                <svg
                                                                                    className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                >
                                                                                    <circle
                                                                                        className="opacity-25"
                                                                                        cx="12"
                                                                                        cy="12"
                                                                                        r="10"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="4"
                                                                                    ></circle>
                                                                                    <path
                                                                                        className="opacity-75"
                                                                                        fill="currentColor"
                                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                    ></path>
                                                                                </svg>
                                                                                <span>
                                                                                    Odesílání...
                                                                                </span>
                                                                            </button>
                                                                        ) : (
                                                                            <>
                                                                                <button
                                                                                    type="submit"
                                                                                    className="bg-gray-700 text-white font-bold py-2 px-10 w-full lg:w-auto rounded hover:bg-gray-600"
                                                                                >
                                                                                    Přidat
                                                                                    komentář
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};
