import { EyeOffIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Loading } from "../layout/Loading";
import { Title } from "../layout/Title";

export const Contacts = (props) => {
    const building = props.building;
    const [contacts] = useState(building.contacts);
    const [loading] = useState(false);

    return (
        <>
            <div className=" p-5 lg:p-8 bg-gray-100 bg-opacity-90">
                <div className="col-span-2 space-y-4">
                    <Title bold={"Kontakty"} />
                    <h3 className="text-lg text-gray-500 md:text-xl mx-auto  mb-4">
                        Výbor
                    </h3>
                    <section className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 ">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3"
                                    >
                                        Jméno
                                    </th>
                                    {building?.unit?.units_flat_number ===
                                        "1" && (
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3"
                                        >
                                            Jednotka
                                        </th>
                                    )}
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/3"
                                    >
                                        Telefon
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        E-mail
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts?.a &&
                                    contacts.a.map((person, personIdx) => (
                                        <tr
                                            key={personIdx}
                                            className={
                                                personIdx % 2 === 0
                                                    ? "bg-white"
                                                    : "bg-gray-50"
                                            }
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {person.name}
                                            </td>
                                            {building?.unit
                                                ?.units_flat_number === "1" && (
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                                            )}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {person.tel ? (
                                                    person.tel
                                                ) : (
                                                    <EyeOffIcon className="w-5 opacity-30" />
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {person.email ? (
                                                    person.email
                                                ) : (
                                                    <EyeOffIcon className="w-5 opacity-30" />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </section>

                    <h3 className="text-lg text-gray-500 md:text-xl mx-auto   ">
                        Kontakty na vlastníky
                    </h3>

                    <section className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 ">
                            <tbody>
                                {contacts?.b &&
                                    contacts.b.map((person, personIdx) => (
                                        <tr
                                            key={personIdx}
                                            className={
                                                personIdx % 2 === 0
                                                    ? "bg-white"
                                                    : "bg-gray-50"
                                            }
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 w-1/3">
                                                {person.name}
                                            </td>
                                            {building?.unit
                                                ?.units_flat_number === "1" && (
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-[100px] ">
                                                    <span
                                                        className="truncate"
                                                        title={person?.flat}
                                                    >
                                                        {person?.flat}
                                                    </span>
                                                </td>
                                            )}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/4">
                                                {person.tel ? (
                                                    person.tel
                                                ) : (
                                                    <EyeOffIcon className="w-5 opacity-30" />
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {person.email ? (
                                                    person.email
                                                ) : (
                                                    <EyeOffIcon className="w-5 opacity-30" />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            <Loading loading={loading} />
        </>
    );
};
