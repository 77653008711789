import {
    MailIcon,
    PhoneIcon,
    ChevronRightIcon,
    ExternalLinkIcon,
} from "@heroicons/react/solid";
import { useContext } from "react";
import { AuthContext } from "../context/context";
import { DashboardFacilityItem } from "../dashboard/DashboardFacilityItem";
import { DashboardPost } from "../dashboard/DashboardPost";
import { Info } from "../layout/Info";

export const Home = (props) => {
    const building = props.building;
    const contacts = building?.unit?.units_contacts;
    const messageboard = building?.messageboard;

    const [context, dispatch] = useContext(AuthContext);

    function infoModal() {
        dispatch({
            type: "SET_MODAL",
            payload: {
                component: Info,
                params: {
                    title: "Důležité kontakty",
                    text: contacts,
                },
            },
        });
    }

    return (
        <>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 p-5 lg:p-8 bg-gray-100 bg-opacity-90">
                <div className="col-span-2 space-y-4">
                    {building?.dashboard && (
                        <>
                            <h3 className="text-lg text-gray-500 md:text-xl mx-auto  mb-4">
                                Informace od správce objektu
                            </h3>

                            <div className="space-y-4">
                                {building?.dashboard &&
                                    building?.dashboard.map((dashboard) => (
                                        <DashboardFacilityItem
                                            key={dashboard.id}
                                            dashboard={dashboard}
                                            cut={true}
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                    {messageboard && (
                        <>
                            <h3 className="text-lg text-gray-500 md:text-xl mx-auto   ">
                                Nástěnka objektu
                            </h3>

                            <section>
                                <div className="  sm:grid sm:grid-cols-2 sm:gap-px">
                                    <h2 className="sr-only">
                                        Nástěnka objektu
                                    </h2>
                                    {messageboard.map((message, messageIdx) => (
                                        <DashboardPost
                                            key={message.id}
                                            message={message}
                                        />
                                    ))}
                                </div>
                            </section>
                        </>
                    )}
                </div>

                <div className="col-span-2 lg:col-span-1 space-y-4">
                    <h3 className="text-lg text-gray-500 md:text-xl mx-auto   ">
                        Kontakt na správce
                    </h3>
                    {building && building.contact && (
                        <div className="bg-white border shadow-md rounded-md overflow-hidden ">
                            <div className="sm:flex sm:items-center sm:justify-between px-6 py-1">
                                <div className="sm:flex sm:space-x-5">
                                    <div className="flex items-center  py-4  ">
                                        <div className="min-w-0 flex-1 flex  ">
                                            <div className="flex-shrink-0 ">
                                                {building &&
                                                building.contact &&
                                                building?.contact
                                                    ?.users_data_img ? (
                                                    <img
                                                        className="h-14 rounded-full"
                                                        src={
                                                            building?.contact
                                                                ?.users_data_img
                                                        }
                                                        alt=""
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="min-w-0 flex-1 px-4  ">
                                                <div>
                                                    <p className="text-base font-medium text-gray-800 truncate">
                                                        {
                                                            building?.contact
                                                                ?.users_data_pre
                                                        }{" "}
                                                        {
                                                            building?.contact
                                                                ?.users_data_fname
                                                        }{" "}
                                                        {
                                                            building?.contact
                                                                ?.users_data_surname
                                                        }
                                                    </p>
                                                    <p className=" flex items-center text-sm text-gray-500 mb-2">
                                                        Manažer správy
                                                        nemovitostí
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid py-3 space-y-3 justify-items-stretch text-sm text-gray-500 bg-gray-100  divide-x divide-gray-200 border-t border-gray-200">
                                {building?.contact?.users_data_tel && (
                                    <div className="flex justify-start space-x-2 items-center px-3  ">
                                        <div className="flex items-center rounded-full ">
                                            <PhoneIcon className="w-4" />
                                        </div>{" "}
                                        <span>
                                            {building?.contact?.users_data_tel}
                                        </span>
                                    </div>
                                )}
                                <div className="flex justify-start space-x-2 items-center px-3 ">
                                    <div className="flex items-center rounded-full ">
                                        <MailIcon className="w-4" />
                                    </div>{" "}
                                    <a
                                        href={
                                            "mailto:" +
                                            building?.contact?.users_data_email
                                        }
                                    >
                                        {building?.contact?.users_data_email}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className=" rounded-md overflow-hidden ">
                        <div className="sm:flex sm:items-center sm:justify-between px-9 py-1">
                            <div className="sm:flex ">
                                <div className="flex items-center  py-4  ">
                                    <div className="min-w-0 flex-1 flex  ">
                                        <div className="min-w-0 flex-1 ">
                                            <div>
                                                <p className="text-base font-medium text-gray-800 truncate">
                                                    STEPHANOS FACILITY ONLINE{" "}
                                                </p>
                                                <p className=" flex items-center text-sm text-gray-500 mb-2">
                                                    Nad Kazankou 172/57 <br />
                                                    171 00 Praha 7 - Troja
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 grid-rows-2 py-3 space-y-0  grid-flow-col gap-3 justify-items-stretch text-sm text-gray-500  ">
                            <div className="flex justify-start space-x-2 items-center px-3  ">
                                <div className="flex items-center rounded-full ">
                                    <PhoneIcon className="w-4" />
                                </div>{" "}
                                <span>+420 776 767 767</span>
                            </div>

                            <div className="flex justify-start space-x-2 items-center px-3  ">
                                <div className="flex items-center rounded-full ">
                                    <PhoneIcon className="w-4" />
                                </div>{" "}
                                <span>+420 283 881 866</span>
                            </div>
                            <div className="flex justify-start space-x-2 items-center px-3 ">
                                <div className="flex items-center rounded-full ">
                                    <MailIcon className="w-4" />
                                </div>{" "}
                                <a href={"mailto:info@stephanos.cz"}>
                                    info@stephanos.cz
                                </a>
                            </div>
                            <div className="flex justify-start space-x-2 items-center px-3 ">
                                <div className="flex items-center rounded-full ">
                                    <ExternalLinkIcon className="w-4" />
                                </div>{" "}
                                <a
                                    href="https://www.stephanos.cz/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.stephanos.cz
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr className="border-t" />

                    {contacts && (
                        <button
                            onClick={() => infoModal()}
                            className=" mr-2 mb-2 justify-between w-full  bg-green-600 border border-green-700 rounded-md shadow-sm p-4 flex items-center font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <div className="flex items-center">
                                <PhoneIcon
                                    className="mr-3 h-5 w-5 opacity-60"
                                    aria-hidden="true"
                                />
                                <span>Důležité kontakty</span>
                            </div>
                            <ChevronRightIcon className="w-5" />
                        </button>
                    )}
                    {building?.unit?.units_img && (
                        <>
                            <div className=" bg-white rounded-lg overflow-hidden ">
                                <div className="    bg-center relative h-64 ">
                                    <img
                                        src={building.unit.units_img}
                                        alt=""
                                        className="shadow object-cover h-64 w-full"
                                    />
                                    {/**
                                  * 
                                  <div className="w-full h-full bg-black opacity-50 absolute"></div>
                                  */}
                                    <div className="absolute bottom-0 left-0 p-5 w-full flex backdrop-filter backdrop-blur-sm bg-black bg-opacity-50 text-white rounded-b-md">
                                        {building.unit.units_name}
                                    </div>
                                </div>
                            </div>
                            <hr className="border-t" />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
