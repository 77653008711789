import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Loading } from "../layout/Loading";
import { Title } from "../layout/Title";
import Branch from "./documents/Branch";
import Files from "./documents/Files";

export const Documents = (props) => {
    const building = props.building;
    const [data, setData] = useState([]);
    const [personalData, setPersonalData] = useState([]);
    const [map, setMap] = useState([]);
    const [loading] = useState(false);
    const [parents, setParents] = useState([]);
    const [personalParents, setPersonalParents] = useState([]);
    const [filelist] = useState(building.documents.files);
    const [activeBranch, setActiveBranch] = useState(false);
    const [activeFolder, setActiveFolder] = useState(false);
    const [flatFolders, setFlatFolders] = useState([]);
    const [searchResults, setSearchResults] = useState({ search: [] });
    const { register, handleSubmit, getValues } = useForm();
    const [folderSettings, setFolderSettings] = useState(
        JSON.parse(localStorage.getItem("folderSettings"))
    );

    function search() {
        const val = getValues("search");

        let files = filelist;
        let result = [];
        let l;
        var searchVal = val.split(" ").filter(Boolean);
        if (files) {
            Object.keys(files).forEach(function (key, index) {
                // eslint-disable-next-line
                files[key].filter((file) => {
                    let fileName = file.name
                        .toUpperCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                    var matchCount = 0;

                    //Check if Substrings are found in MainString
                    for (l = 0; l < searchVal.length; l++) {
                        if (
                            fileName.indexOf(
                                searchVal[l]
                                    .toUpperCase()
                                    .normalize("NFD")
                                    .replace(/[\u0300-\u036f]/g, "")
                            ) > -1
                        ) {
                            matchCount++;
                        }
                    }
                    if (
                        searchVal &&
                        searchVal.length > 0 &&
                        matchCount === searchVal.length
                    ) {
                        result.push(file);
                    }
                });
            });
        }
        setSearchResults({ search: result });
    }

    const updateFolderSettings = (settings) => {
        localStorage.setItem("folderSettings", JSON.stringify(settings));
        setFolderSettings(settings);
    };

    useMemo(() => {
        !folderSettings &&
            updateFolderSettings({
                order: "name",
                direction: "desc",
                months: true,
            });
        // eslint-disable-next-line
    }, [building]);

    const debounce = (func, debounceTimeout) => {
        let timeout;
        return function executedFunction(...args) {
            const delayedFunction = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(delayedFunction, debounceTimeout);
        };
    };

    // eslint-disable-next-line
    const debouncedSubmit = useCallback(
        debounce((e) => {
            search(e);
        }, 500),
        []
    );

    useEffect(
        () => {
            function menuItems(items, personal = false) {
                const list = items;
                const flat = items;
                var map = {},
                    node,
                    roots = [],
                    i,
                    path = [];

                for (i = 0; i < list.length; i += 1) {
                    map[list[i].id] = i; // initialize the map
                    path[list[i].id] = []; // initialize the map
                    list[i].children = []; // initialize the children
                    flat[i].children = []; // initialize the flat children
                }

                for (i = 0; i < list.length; i += 1) {
                    node = list[i];
                    if (node.parentId !== 0) {
                        // if you have dangling branches check that map[node.parentId] exists
                        list[map[node.parentId]].children.push(node);
                        //flat[map[node.id]].children.push(node);
                        //path[node.parentId].push(node.id);
                        path[node.id].push(node.parentId);
                        path[node.id].push(list[map[node.parentId]].parentId);
                    } else {
                        roots.push(node);
                        path[node.id].push(node.parentId);
                    }
                }

                if (!personal) {
                    setParents(path);
                    setMap(map);
                    setFlatFolders(flat);
                } else {
                    setPersonalParents(path);
                }

                return roots;
            }

            let newMenu =
                building.documents.folders &&
                menuItems(building.documents.folders);
            setData(newMenu);

            if (building?.documents?.personal?.length > 0) {
                let personalMenu = menuItems(building.documents.personal, true);
                setPersonalData(personalMenu);
            }

            // setLoading(true);
            // axiosProtected.get('units/documents/' + building?.unit?.units_id + '/')
            //   .then(res => {
            //      setFileList(res.data.files);
            //      let newMenu = menuItems(res.data.folders);
            //      setData(newMenu);
            //      setLoading(false);
            //   })
            //     .catch(err => {
            //       console.log(err);
            //     });
            //   }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <div className=" p-5 lg:p-8 bg-gray-100 bg-opacity-90 lg:grid lg:grid-cols-6 gap-5 ">
                <div className="lg:col-span-4 space-y-4  overflow-hidden">
                    <Title bold={"Knihovna"} title={"dokumentů"} />
                </div>
                <div className="lg:col-span-2">
                    <div className=" flex flew-wrap  content-center items-center  relative pb-4 lg:pb-0">
                        <svg
                            className="text-gray-300 w-7 absolute  left-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>

                        <form
                            onSubmit={handleSubmit(debouncedSubmit)}
                            className=" inline w-full"
                        >
                            <input
                                {...register("search")}
                                onKeyUp={() => debouncedSubmit()}
                                className="px-3 py-3 w-full pl-14 rounded-lg focus:outline-none border-none focus:border-none focus:ring-2 focus:ring-green-600"
                                type="search"
                                autoComplete="off"
                                placeholder="Vyhledávání"
                            />
                        </form>
                    </div>
                </div>
                {searchResults.search.length > 0 && (
                    <div className="lg:col-span-6  w-full overflow-hidden">
                        <section className="bg-white px-4 py-6 shadow sm:p-6 rounded-md mt-1">
                            <div className="w-full ">
                                <h3 className="text-base text-gray-500  font-medium mx-auto border-b border-gray-200 pb-2 mb-4">
                                    Výsledky vyhledávání
                                </h3>
                            </div>
                            <Files
                                files={searchResults}
                                activeFolder={"search"}
                                folders={false}
                                map={map}
                                activeBranch={activeBranch}
                                setActiveBranch={setActiveBranch}
                                setActiveFolder={setActiveFolder}
                                parents={parents}
                                updateFolderSettings={updateFolderSettings}
                                folderSettings={folderSettings}
                                setSearchResults={setSearchResults}
                            />
                        </section>
                    </div>
                )}
                <div
                    className={
                        " space-y-4 w-full overflow-hidden " +
                        (activeFolder ? "col-span-2" : "col-span-6")
                    }
                >
                    <section className="bg-white px-4 py-6 shadow sm:p-6 rounded-md w-full overflow-hidden">
                        {personalData.length > 0 && (
                            <>
                                <div className="mb-2 border-b border-gray-200 pb-2">
                                    {personalData.map((item, itemIdx) => {
                                        return (
                                            <Branch
                                                key={item.id}
                                                branch={itemIdx}
                                                item={item}
                                                level={0}
                                                activeBranch={activeBranch}
                                                setActiveBranch={
                                                    setActiveBranch
                                                }
                                                activeFolder={activeFolder}
                                                setActiveFolder={
                                                    setActiveFolder
                                                }
                                                parents={personalParents}
                                                files={filelist}
                                                updateFolderSettings={
                                                    updateFolderSettings
                                                }
                                                folderSettings={folderSettings}
                                                setSearchResults={
                                                    setSearchResults
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        )}

                        {data &&
                            data.map((item, itemIdx) => {
                                return (
                                    <Branch
                                        key={item.id}
                                        branch={itemIdx}
                                        item={item}
                                        level={0}
                                        activeBranch={activeBranch}
                                        setActiveBranch={setActiveBranch}
                                        activeFolder={activeFolder}
                                        setActiveFolder={setActiveFolder}
                                        parents={parents}
                                        files={filelist}
                                        updateFolderSettings={
                                            updateFolderSettings
                                        }
                                        folderSettings={folderSettings}
                                        setSearchResults={setSearchResults}
                                    />
                                );
                            })}
                    </section>
                </div>
                <div className="hidden lg:block col-span-4 space-y-4 ">
                    {filelist && (
                        <section
                            className={
                                activeFolder
                                    ? "bg-white px-4 py-6 shadow sm:p-6 sm:pt-2 rounded-md "
                                    : ""
                            }
                        >
                            <Files
                                files={filelist}
                                updateFolderSettings={updateFolderSettings}
                                folderSettings={folderSettings}
                                activeFolder={activeFolder}
                                folders={flatFolders}
                                map={map}
                                activeBranch={activeBranch}
                                setActiveBranch={setActiveBranch}
                                setActiveFolder={setActiveFolder}
                                parents={parents}
                                setSearchResults={setSearchResults}
                            />
                        </section>
                    )}
                </div>
            </div>
            <Loading loading={loading} />
        </>
    );
};
