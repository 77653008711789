import React, { useState, useEffect } from "react"; 
import { ChatAltIcon, EyeOffIcon } from "@heroicons/react/solid"
import { useParams  } from "react-router-dom"
import { format  } from 'date-fns'
import { FileList } from "../layout/FileList";
import {File} from "../facility/documents/Files";

export const DashboardFacilityDetail = (props) => {
    let { prispevekId } = useParams();
    const building = props.building; 

    
    const [message,setItem] = useState(building.dashboard_all.find(x => x.id === prispevekId));

    useEffect(() => { 
      const found = building.dashboard_all.find(x => x.id === prispevekId);
      setItem(found);
      
    }
        // eslint-disable-next-line
    , [prispevekId]);
  
    return (
        <>
        {message ? (
            <>
                     <div className="w-full ">
                     <h3 className="text-lg text-gray-800 md:text-xl mx-auto  mb-4">
                     
                     {message?.name}
                        </h3>
                      
                      
                    <div className={"relative group border  bg-white   p-6  text-left pb-20 shadow-md rounded-md "  } > 

                      <div >
                      
                        <div
                        className="mt-2 text-sm text-gray-800 space-y-4 "
                        dangerouslySetInnerHTML={{ __html: message.text }}
                      />

                      
                        {message?.documents && message?.documents?.length > 0 && (
                          <div className="my-2 text-sm border-t pt-3">
                            {message?.documents.map((file,itemIdx) => {
                              return <File key={itemIdx} file={file} />
                            })}
                            </div>
                         )}

                        {message?.images && message?.images?.length > 0 && (
                             <FileList files={message?.images} setModalContent={props.setModalContent} setModalIsOpen={props.setModalIsOpen} />
                         )}
   
                      </div>
                      <div className="mt-6 flex justify-between space-x-8 absolute w-full bottom-4 left-0 px-6">
                        <div className="flex space-x-6">
                        {message.visible === '0' && <>
                            <span className="inline-flex items-center text-sm">
                              <EyeOffIcon className="text-red-600 w-5" />
                            </span>
                          </>}
                          
                          {message.comment_count > 0 && 
                          
                            <span className="inline-flex items-center text-sm">
                              <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                                <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                                <span className="font-medium text-gray-900">{message.comment_count}</span>
                                <span className="sr-only">komentáře</span>
                              </button>
                            </span>
                          }
                           
                        </div>
                        <div className="flex space-x-3">
                          <div className="flex-shrink-0">
                            {message?.author_img ? (
                              <img className="h-10 w-10 rounded-full" src={message.author_img} alt="" />
                            ) : (
                              ""
                            )
                            }
                          </div>
                          <div className="min-w-0 flex-1">
                            <p className="text-sm font-medium text-gray-900">
                              
                                {message?.author}
                              
                            </p>
                            <p className="text-sm text-gray-500 text-right">
                              
                                <time dateTime={message.datetime}>{format(new Date(message.date), 'd. M. y')}</time>
                               
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center flex">
                             
                          </div>
                        </div>
                      </div> 
                      {message.visible === '0' && <>
                            <span className="absolute inset-0 bg-gray-100 bg-opacity-50 rounded-md">
                               
                            </span>
                    </>}

                  
                     
                    </div>
                </div>

                    </>
            ) : ""}
 
        </>
    )
}