import { XIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { axiosProtected } from "../../client";

export const CommentDelete = ({building,item, setBuilding,dialog}) => {
       
    const [loading ] = useState(false);   
    const { register, handleSubmit  } = useForm();   
     
    const onSubmit = async data => {  
        await (axiosProtected()).post('units/comment_delete/', data).then(res => { 
            //console.log(res.data)
            setBuilding('',res.data) 
        }).catch(err => {        
          console.log(err)
        })

    };

    return (
        (building.group ==='spravce' || building.group ==='superadmin' || building.group ==='admin') &&
        <>
             <form onSubmit={() => dialog('Opravdu odstranit?',() => handleSubmit(onSubmit)())} >
                            {item && 
                            <>
                              <input type="hidden" {...register("item_id")} name="item_id" defaultValue={item?.id} />
                              <input type="hidden" {...register("unit_id")} name="unit_id" defaultValue={building?.unit?.units_id} />
                              </>
                            }
                               
                              <div className="mt-2">
                                  {loading ? (
                                    
                                    <button disabled className="  text-gray-800  w-full lg:w-auto rounded cursor-default">
                                      <svg className="animate-spin  h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg> </button>
                                  ) : (
                                    <>
                                    <button type="submit" className=" w-full lg:w-auto text-gray-400 rounded hover:text-gray-700"
                                    ><XIcon className="w-5" /></button>
                                    
                                    </>
                                  )}


                              </div>

                          </form>
            
        
        </>
    )
}