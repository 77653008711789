export const Empty = (props) => {
    return(
        <>
        <ul id="gallery" className="flex flex-1 flex-wrap my-10 ">
             <li id="empty" className="h-full w-full text-center flex flex-col justify-center items-center  ">
               
                   <svg className="w-32 mx-auto text-gray-300" fill="currentColor" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
                     viewBox="0 0 512 512" >
                   
                       <path d="M510.194,221.846L441.735,84.353c-2.922-5.862-8.905-9.567-15.452-9.567H85.717c-6.547,0-12.53,3.705-15.452,9.567
                         L1.806,221.846C0.616,224.233,0,226.868,0,229.537v190.418c0,9.532,7.726,17.258,17.258,17.258h477.483
                         c9.532,0,17.258-7.726,17.258-17.258V229.537C512,226.868,511.384,224.233,510.194,221.846z M96.4,109.303h319.195l51.562,103.551
                         H355.524c-8.158,0-15.216,5.782-16.902,13.761c-0.616,2.917-15.872,71.381-85.498,71.381s-84.883-68.464-85.487-71.312
                         c-1.628-8.048-8.704-13.83-16.913-13.83H44.843L96.4,109.303z M477.483,402.697H34.517V247.371h103.108
                         c12.127,35.339,46.644,85.142,115.499,85.142c68.861,0,103.378-49.802,115.499-85.142h108.86V402.697z"/>
                     
                   </svg>

               

               <span className="text-small text-gray-400">Žádné položky</span>
             </li>
           </ul>
     </>
    )
}