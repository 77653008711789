import React from "react";
import { NavLink } from "react-router-dom";

export const DashboardMenu = (props) => {
    return (
        <>
            <div className="  bg-gray-100 bg-opacity-90 rounded-lg shadow ">
                <div>
                    <div className="divide-y divide-gray-200 lg:divide-y-0 lg:divide-x">
                        <div className="divide-y divide-gray-200 lg:col-span-9">
                            <>
                                <nav className="bg-white shadow">
                                    <div className="  px-6 lg:px-8">
                                        <div className="relative flex justify-between h-16">
                                            <div className="flex-1 flex  items-stretch justify-between">
                                                <div className=" flex  space-x-4 lg:space-x-8 ">
                                                    <NavLink
                                                        to={"/nastenka/spravce"}
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            "border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium truncate " +
                                                            (isActive
                                                                ? "border-b-green-500"
                                                                : "")
                                                        }
                                                    >
                                                        <span className="hidden lg:block">
                                                            Informace od správce
                                                        </span>
                                                        <span className="block lg:hidden">
                                                            Od správce
                                                        </span>
                                                    </NavLink>
                                                    <NavLink
                                                        to={
                                                            "/nastenka/prispevky"
                                                        }
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            "border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium " +
                                                            (isActive
                                                                ? "border-b-green-500"
                                                                : "")
                                                        }
                                                    >
                                                        Nástěnka
                                                    </NavLink>
                                                    <NavLink
                                                        to={"/nastenka/novy"}
                                                        className={({
                                                            isActive,
                                                        }) =>
                                                            "border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium truncate " +
                                                            (isActive
                                                                ? "border-b-green-500"
                                                                : "")
                                                        }
                                                    >
                                                        Nový příspěvek
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </>

                            <div className="px-3 lg:px-8 py-5">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
