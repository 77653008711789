import React, { useContext, useState } from "react";
import { Loading } from "../layout/Loading";
import { Title } from "../layout/Title";
import { format } from "date-fns";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../context/context";
import { SALDO, SALDO_USER } from "../../query/saldo";

export const Economics = () => {
    const [context] = useContext(AuthContext);
    const groups = ["spravce", "vybor", "admin", "superadmin"];

    const building = context.building;
    const group = context.building?.group;

    let query;

    let master = groups.includes(group);
    if (master) {
        query = SALDO;
    } else {
        query = SALDO_USER;
    }

    const { loading, data } = useQuery(query, {
        variables: {
            unitId: parseInt(building?.unit?.units_id),
            usersDataId: context?.userData?._id,
        },
        fetchPolicy: "cache-and-network",
    });

    const saldo = data?.saldos || [];
    const summary = data?.saldoSummaries || [];

    return (
        <>
            <div className=" p-5 lg:p-8 bg-gray-100 bg-opacity-90">
                <div className="col-span-2 space-y-4">
                    <Title bold={"Ekonomické"} title={"přehledy"} />

                    {summary && summary.length > 0 && building && master ? (
                        <section className="bg-white px-8 py-6 shadow  rounded-md mt-1 w-full lg:w-auto lg:inline-flex ">
                            <table className="table-auto ">
                                <tbody>
                                    <tr className="text-gray-600 text-sm">
                                        <td className="p-2">Přehled ke dni</td>
                                        <td>
                                            {format(
                                                new Date(summary[0].inserted),
                                                "d. M. y"
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="text-gray-600 text-sm">
                                        <td className="p-2 text-red-600">
                                            Celkový součet nedoplatků:
                                        </td>
                                        <td>
                                            <span className="font-medium text-red-600">
                                                {summary[0].nedoplatek.toLocaleString(
                                                    "cs-CZ"
                                                )}{" "}
                                                Kč
                                            </span>{" "}
                                        </td>
                                    </tr>
                                    <tr className="text-gray-600 text-sm">
                                        <td className="p-2">
                                            Celkový součet přeplatků:
                                        </td>
                                        <td>
                                            <span className="font-medium">
                                                {summary[0].preplatek.toLocaleString(
                                                    "cs-CZ"
                                                )}{" "}
                                                Kč
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    ) : (
                        saldo &&
                        saldo.length > 0 && (
                            <section className="bg-white px-8 py-6 shadow  rounded-md mt-1 w-full lg:w-auto lg:inline-flex ">
                                <table className="table-auto ">
                                    <tbody>
                                        <tr className="text-gray-600 text-sm">
                                            <td className="p-2">
                                                Přehled ke dni
                                            </td>
                                            <td>
                                                {format(
                                                    new Date(saldo[0].inserted),
                                                    "d. M. y"
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </section>
                        )
                    )}
                    {saldo && saldo.length > 0 && building ? (
                        <>
                            <section className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200 ">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                            >
                                                Uživatel
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                            >
                                                Jednotka
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Typ
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider "
                                            >
                                                Předpis
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Uhrazeno
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                K úhradě
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {saldo &&
                                            saldo.map((econ, revIdx) => (
                                                <tr
                                                    key={revIdx}
                                                    className={
                                                        " " +
                                                        (revIdx % 2 === 0
                                                            ? "bg-white"
                                                            : "bg-gray-50") +
                                                        (econ.sum > 0
                                                            ? " text-red-600 "
                                                            : " text-gray-700")
                                                    }
                                                >
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium  ">
                                                        {econ.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium  ">
                                                        {econ.flat}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm  ">
                                                        {econ.type}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm   text-right">
                                                        {parseInt(
                                                            econ.topay
                                                        ).toLocaleString(
                                                            "cs-CZ"
                                                        )}{" "}
                                                        Kč
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm   text-right">
                                                        {parseInt(
                                                            econ.paid
                                                        ).toLocaleString(
                                                            "cs-CZ"
                                                        )}{" "}
                                                        Kč
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right">
                                                        {parseInt(
                                                            econ.sum
                                                        ).toLocaleString(
                                                            "cs-CZ"
                                                        )}{" "}
                                                        Kč
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </section>
                        </>
                    ) : (
                        !loading && (
                            <>
                                <div className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2 relative border  ">
                                    <article className="text-gray-500 py-5">
                                        <div>
                                            <p>
                                                {" "}
                                                K Vašemu uživatelskému účtu
                                                nejsou nastaveny jednotky.
                                            </p>

                                            <p>
                                                Pro změnu nastavení kontaktujte
                                                správce.
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>

            <Loading loading={loading} />
        </>
    );
};
