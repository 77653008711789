import { useState } from "react";
import { useForm } from "react-hook-form";
import { axiosProtected } from "../../client";

export const DashboardApprove = ({building,item, setBuilding}) => {
       
    const [loading, setLoading] = useState(false);  
    const { register, handleSubmit } = useForm();   
    

    const onSubmit = async data => {  
    setLoading(true); 
    await (axiosProtected()).post('units/messageboard_approve/', data).then(res => { 
        //console.log(res.data)
        setBuilding('',res.data)
        setLoading(false);
    }).catch(err => {       
        setLoading(false);
    })

    };

    return (
        (building.group ==='vybor' || building.group ==='spravce' || building.group ==='superadmin' || building.group ==='admin') &&
        <>
             <form onSubmit={handleSubmit(onSubmit)} >
                            {item && 
                              <input type="hidden" {...register("item_id")} name="item_id" defaultValue={item?.id} />
                            }
                               
                              <div className="mt-2">
                                  {loading ? (
                                    
                                    <button disabled className="bg-gray-200 text-gray-800 font-bold py-2 px-10 w-full lg:w-auto rounded cursor-default">
                                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 inline" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg>
                                      <span>Zpracování...</span></button>
                                  ) : (
                                    <>
                                    <button type="submit" className="bg-gray-700 text-white font-bold py-2 px-10 w-full lg:w-auto rounded hover:bg-gray-600"
                                      
                                    >Zveřejnit</button>
                                    
                                    </>
                                  )}


                              </div>

                          </form>
            
        
        </>
    )
}