import React, {useEffect} from "react";  
import { NavLink } from "react-router-dom"; 

export const SignUpSent = ({setTitle}) => {  

  useEffect(() => {
    setTitle('Formulář odeslán')
    // eslint-disable-next-line
  }, []);

    return(
        <>
           
            <div  className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2"> 
                <div className="w-full p-8 lg:w-2/3">
                     
                    <div className="pt-8">
                    <div>
                      <h3 className="text-2xl leading-6 my-5 font-medium text-green-500">
                       Registrace odeslána
                      </h3>
                      <div className="mb-4"><p>Děkujeme za vyplnění formuláře.</p> </div>
                      <div className="mb-10"><p>Po schválení Vám bude nastaven objekt, ke kterému máte oprávnění.</p> </div>

                      <NavLink to={'/'} className="bg-gray-500 mb-20 inline-block text-white font-bold py-4 px-4   rounded hover:bg-gray-600"
                          
                      >
                          Zpět na úvod
                      </NavLink>

                    </div>
                    
                  </div>
                 </div>  
              </div>  
             
     
        </>
    )
}