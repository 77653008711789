import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextEditor from "../form/editor";
import FileUpload from "../fileUpload";
import serialize from "../form/form";
import { Error } from "../form/form";
import { axiosProtected } from "../../client";
import { Loading } from "../layout/Loading";

export const DashboardAdd = (props) => {
    const setModal = props.setModal;
    const setBuilding = props.setBuilding;
    const [uploadId, setId] = useState();
    const [submittedData] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const building = props.building;
    const user = props.user;

    const [value, setEditorValue] = useState([
        {
            type: "paragraph",
            children: [{ text: "" }],
        },
    ]);
    function setMobileEditor(e) {
        setEditorValue([
            {
                type: "paragraph",
                children: [{ text: e.target.value }],
            },
        ]);
    }

    const { register, handleSubmit, reset, setValue, formState } = useForm();

    let errors = formState.errors;

    useEffect(() => {
        if (!uploadId) {
            const id =
                "upload_" +
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
            setId(id);
            setValue("uploadId", id);
        }
        // eslint-disable-next-line
    }, [uploadId]);

    useEffect(() => {
        setValue("text", serialize(value));
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({});
        }
    }, [formState, submittedData, reset]);

    const onSubmit = async (data) => {
        setLoading(true);
        await axiosProtected()
            .post("units/dashboard_add/", data)
            .then((res) => {
                setLoading(false);
                setBuilding("", res.data);
                navigate("/formular-odeslan");
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 ">
                <div className="col-span-2">
                    <h3 className="text-lg text-gray-500 md:text-xl mx-auto border-b border-gray-200 pb-2">
                        <span className="font-bold">Nový</span> příspěvek
                    </h3>
                    <div className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className=" pb-8"
                        >
                            {building && building.unit && (
                                <input
                                    type="hidden"
                                    {...register("units_id")}
                                    name="units_id"
                                    defaultValue={building.unit.units_id}
                                />
                            )}
                            {building && building.contact && (
                                <input
                                    type="hidden"
                                    {...register("contact")}
                                    name="contact"
                                    defaultValue={
                                        building?.contact?.users_data_id
                                    }
                                />
                            )}

                            <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 ">
                                {user ? (
                                    <>
                                        <input
                                            type="hidden"
                                            name="email"
                                            {...register("email", {
                                                required: true,
                                            })}
                                            defaultValue={user.eml}
                                        />
                                        <input
                                            type="hidden"
                                            name="user_id"
                                            {...register("user_id", {
                                                required: true,
                                            })}
                                            defaultValue={user.id}
                                        />
                                    </>
                                ) : null}

                                <div
                                    className={
                                        building &&
                                        building.unit &&
                                        building.unit.groups_description &&
                                        (building.unit.groups_alias ===
                                            "spravce" ||
                                            building.unit.groups_alias ===
                                                "admin" ||
                                            building.unit.groups_alias ===
                                                "superadmin")
                                            ? "sm:col-span-4"
                                            : "sm:col-span-6"
                                    }
                                >
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Název
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            {...register("name", {
                                                required: true,
                                            })}
                                            className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.name && (
                                            <Error text="Vyplňte název" />
                                        )}
                                    </div>
                                </div>
                                {building &&
                                building.unit &&
                                building.unit.groups_description &&
                                (building.unit.groups_alias === "spravce" ||
                                    building.unit.groups_alias === "admin" ||
                                    building.unit.groups_alias ===
                                        "superadmin") ? (
                                    <div className="sm:col-span-2">
                                        <label
                                            htmlFor="destination"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Umístění
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="destination"
                                                {...register("destination")}
                                                name="destination"
                                                className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            >
                                                <option value="dashboard">
                                                    informace od správce
                                                </option>
                                                <option value="messageboard">
                                                    nástěnka
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                ) : null}

                                <div className="sm:col-span-4">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Zobrazit pouze v období
                                    </label>
                                    <div className="mt-1 grid md:flex space-y-2 md:space-y-0 md:space-x-2">
                                        <div>
                                            <input
                                                type="date"
                                                name="from"
                                                id="from"
                                                {...register("from")}
                                                className="shadow-sm focus:ring-green-500 focus:border-green-500  sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="date"
                                                name="to"
                                                id="to"
                                                {...register("to")}
                                                className="shadow-sm focus:ring-green-500 focus:border-green-500   sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:col-span-6">
                                    <label
                                        htmlFor="street_address"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Text
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            className="hidden"
                                            name="text"
                                            {...register("text", {
                                                required: true,
                                                minLength: 8,
                                            })}
                                            onChange={() => ""}
                                            defaultValue={serialize(value)}
                                        ></textarea>
                                        <input
                                            type="hidden"
                                            {...register("uploadId")}
                                            name="uploadId"
                                            defaultValue={uploadId}
                                        />
                                        <div className="hidden md:block">
                                            <TextEditor
                                                value={value}
                                                setValue={setEditorValue}
                                                placeholder={"Text příspěvku"}
                                            />
                                        </div>
                                        <textarea
                                            className="md:hidden rounded border border-gray-300 w-full h-32"
                                            name="text2"
                                            onChange={(e) => setMobileEditor(e)}
                                        ></textarea>
                                        {errors.text && (
                                            <Error text="Vložte text příspěvku" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <label
                                htmlFor="street_address"
                                className="block text-sm mt-3 font-medium text-gray-700"
                            >
                                Přílohy
                            </label>
                            <FileUpload
                                uploadId={uploadId}
                                setModal={setModal}
                                module={"messageboard"}
                            />

                            <div className="mt-8">
                                {loading ? (
                                    <button
                                        disabled
                                        className="bg-gray-200 text-gray-800 font-bold py-4 px-4 w-full rounded cursor-default"
                                    >
                                        <svg
                                            className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        <span>Odesílání...</span>
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            type="submit"
                                            className="bg-gray-700 text-white font-bold py-4 px-20  rounded hover:bg-gray-600"
                                        >
                                            Odeslat
                                        </button>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Loading loading={loading} />
        </>
    );
};
