
import React, { useState, useEffect, useRef } from "react"; 
import { useForm } from "react-hook-form";    
import {  
  ChevronRightIcon, 
} from '@heroicons/react/solid' 
import axiosInstance from "../../../client/index.js";
import { Address } from "./Address.js"; 
import { Loading } from "../../layout/Loading.js";
import { navigate } from "@reach/router"  
import { Error } from "../../form/form";

 
export default function Userdata(props) { 

   const [loading, setLoading] = useState(false);    
   const [invoice, setInvoice] = useState(true);    
   const [tenant, setTenant] = useState(false);     
   const [message, setMessage] = useState();
 
    const { register, handleSubmit, setValue, formState: { errors }, watch, unregister  } = useForm({
      mode: "onBlur"
    });   
    
    useEffect(() => {   
      if(invoice) {
        unregister('invoice_address_id');
        unregister('invoice_company');
        unregister('invoice_city');
        unregister('invoice_fname');
        unregister('invoice_surname');
        unregister('invoice_street');
        unregister('invoice_zip');
        unregister('invoice_inum');
        unregister('invoice_vatnum');
      }

    }
    // eslint-disable-next-line 
    , [invoice]); 
 
    const onSubmit = async data => { 
      setLoading(true); 
      try {
        const res = await axiosInstance.post('?e=rstep1', { data })
        if(res) {
          navigate('/registrace-odeslana')
        }
       
      } catch(err) {
        console.log(err);
        if(err.response) {
          setMessage(err?.response?.data?.message);
        }
      }
      setLoading(false); 
    };  
 
    const password = useRef({});
    password.current = watch("password", "");
    
      
    return (
       
            <> 
             {message && <Error text={message} />  }
             
             <form  onSubmit={handleSubmit(onSubmit)}  className={"space-y-8  py-2"  } >

                    
                    <Address  register={register} unregister={unregister} errors={errors} setValue={setValue}   prefix={'transport'} />
 
                <div role="button" tabIndex={0} className=" flex items-center  cursor-pointer w-auto " onClick={() => setInvoice(!invoice)} onKeyUp={() => setInvoice(!invoice)}>
                    <button  
                    type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                    className={(invoice ? 'bg-green-700' : 'bg-gray-300' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                    >
                        <span className="sr-only">kontaktní adresa je shodná</span>
                        <span aria-hidden="true" 
                        className={(invoice ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                        </span>
                    </button>
                    <span className={(invoice ? 'text-gray-700 font-bold' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                        <span >kontaktní adresa je shodná</span>                                   
                    </span>
                </div>


                {!invoice && 
                  <>
                    <div className="text-lg">
                      Kontaktní adresa
                    </div>
                    <Address  register={register} errors={errors} setValue={setValue}  prefix={'invoice'}  />
                  </>
                }

                <div>
                    <div className="mt-4">
                      <label htmlFor="password" className="block text-gray-700 text-sm  font-medium mb-2">Vaše nové heslo</label>
                     
                      <input type="password" id="password"  {...register("password", { required: true })}  
                    className={" shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md  "  }   />
                   {errors?.pass && 
                          <Error text={'Prosím vyplňte heslo'} />                            
                      }
                     
                    </div> 
                    <div className="mt-2">
                      <label htmlFor="password2" className="block text-gray-700  font-medium text-sm mb-2">Vaše nové heslo znovu</label>
                     
                      <input type="password" id="password2" {...register("pass2", { required: true,
                      validate: value => value === password.current || "Hesla bohužel nesouhlasí" })}  
                    className={" shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md  "  }   />
                   
                       
                       {errors?.pass2 && 
                          <Error text={'Prosím opište heslo ' + errors.pass2.message} />                            
                      }
                    </div> 
                  </div>
                 <div>

                  <div role="button" tabIndex={0} className=" flex items-center  cursor-pointer w-auto " onClick={() => setTenant(!tenant)} onKeyUp={() => setTenant(!tenant)}>
                      <button  
                      type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                      className={(tenant ? 'bg-green-700' : 'bg-gray-300' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                      >
                          <span className="sr-only">jsem nájemník</span>
                          <span aria-hidden="true" 
                          className={(tenant ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                          </span>
                      </button>
                      <span className={(tenant ? 'text-gray-700 font-bold' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                          <span >jsem nájemník</span>                                   
                      </span>
                  </div>

                  {tenant &&  
                      <div className=" mt-3">
                        <label htmlFor="owner" className="block text-sm font-medium text-gray-700">
                          Jméno majitele bytu
                        </label>
                        <div className="mt-1">
                            <input type="text"  id="owner"  {...register('owner')}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            
                        </div>
                      </div>
                    }
                </div>

                <div  className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 inset-0 relative ">
                  <div className="sm:col-span-4">
                    <label htmlFor="building" className="block text-sm font-medium text-gray-700">
                        Adresa objektu
                    </label>
                    <div className="mt-1">
                        <input type="text"  id="building"   {...register("adresa_bytove_jednotky")}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                         
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="flat_number" className="block text-sm font-medium text-gray-700">
                        Číslo bytu
                    </label>
                    <div className="mt-1">
                        <input type="text" id="flat_number"  {...register("flat")}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                        
                    </div>
                  </div> 
                </div>

                    <div className="sm:col-span-2 mt-3 flex ">   
                      <button   className="flex items-center   text-white h-12  px-5 py-1 focus:outline-none  space-x-4 bg-green-600 rounded-md border border-gray-200 hover:bg-green-700">
                        <span>Zaregistrovat</span>
                        <ChevronRightIcon className="w-8 " /> 
                      </button> 
                    </div>
              
              </form>  

             
          <Loading loading={loading} />
        </>
       
    )
   }
 
