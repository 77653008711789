import React from "react"; 
import { Title } from "../layout/Title"

export const Vote = (props) => {
    return (
        <>
           <div className="grid  gap-5 p-5 lg:p-8 bg-gray-100 bg-opacity-90">
            <div className="col-span-2 space-y-4">
            
                      <h3 className="text-lg text-gray-500 md:text-xl mx-auto  mb-4">
                      <Title bold={'Hlasování'}  /> 
                        </h3>
                        <div  className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2 relative border   " >
                            <article className="text-gray-500 py-5" >
                                <div>
                         
                                   <p> Váš uživatelský účet nemá v tuto chvíli oprávnění zahájit nové hlasování / anketu.</p>

                                   <p>Pro změnu oprávnění kontaktujte správce.</p>
                                </div>
                            </article>
                        </div>
                </div>
            </div>
        </>
    )
}