import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { DashboardAdd } from "./DashboardAdd";
import { DashboardDetail } from "./DashboardDetail";
import { DashboardFacility } from "./DashboardFacility";
import { DashboardFacilityDetail } from "./DashboardFacilityDetail";
import { DashboardMenu } from "./DashboardMenu";
import { DashboardPosts } from "./DashboardPosts";

export const Dashboard = (props) => {
    const building = props.building;
    const setBuilding = props.setBuilding;
    const dialog = props.dialog;
    const setModalContent = props.setModalContent;
    const setModalIsOpen = props.setModalIsOpen;

    let location = useLocation();

    return (
        <>
            {location.pathname === "/nastenka" && (
                <Navigate to={"/nastenka/spravce"} />
            )}
            <DashboardMenu building={props.building}>
                <Routes>
                    <Route path="spravce">
                        <Route
                            index
                            element={<DashboardFacility building={building} />}
                        />
                        <Route
                            path=":prispevekId"
                            element={
                                <DashboardFacilityDetail
                                    building={building}
                                    setModalIsOpen={setModalIsOpen}
                                    setModalContent={setModalContent}
                                />
                            }
                        />
                    </Route>
                    <Route path="prispevky">
                        <Route
                            index
                            element={<DashboardPosts building={building} />}
                        />
                        <Route
                            path=":prispevekId"
                            element={
                                <DashboardDetail
                                    building={building}
                                    setBuilding={setBuilding}
                                    dialog={dialog}
                                    setModalIsOpen={setModalIsOpen}
                                    setModalContent={setModalContent}
                                />
                            }
                        />
                    </Route>
                    <Route
                        path="novy"
                        element={
                            <DashboardAdd
                                building={building}
                                setBuilding={setBuilding}
                            />
                        }
                    />
                </Routes>
            </DashboardMenu>
        </>
    );
};
