import React, { useState, useEffect } from "react";
import {Error } from '../../form/Error' 
import { 
    XIcon
  } from '@heroicons/react/solid'

export const Address = ({register, unregister, errors, setValue, addressData, sourceData, updateAction, prefix}) => {
   
    const [disabled, setDisabled] = useState(); 
    const [degree, setDegree] = useState();   
       

    useEffect(() => { 
      if(prefix=== 'transport') {
        if(sourceData?.email) {
          setValue('email',sourceData.email)
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      }
    }
      // eslint-disable-next-line 
    , []);
 
     
  
    return (
        <>
 


    <input type="hidden"  {...register(prefix + "_address_id")}  />
    
 
        <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 inset-0 relative ">
            
                 
                    <div className="sm:col-span-3">
                        
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            Jméno  
                        </label>
                        <div className="mt-1">
                            <input type="text" id="first_name"  {...register(prefix + "_fname", { required: true })} className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors[prefix + '_fname'] && 
                            <Error text="Vyplňte jméno" />
                            }
                        </div>
                        </div>

                        <div className="sm:col-span-3">
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Příjmení
                        </label>
                        <div className="mt-1">
                            <input type="text" id="last_name"  {...register(prefix + "_surname", { required: true })}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            {errors[prefix + '_surname'] && 
                            <Error text="Vyplňte příjmení" />
                            }
                        </div>
                        </div> 
                            

                        {degree &&
                        <>
                               <div className="sm:col-span-3">
                            <label htmlFor="pre" className="block text-sm font-medium text-gray-700">
                                Titul před jménem  
                            </label>
                            <div className="mt-1">
                                <input type="text"  id="pre"   {...register(prefix + "_pre")}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                
                            </div>
                            </div>
                            <div className="sm:col-span-3">
                            <label htmlFor="post" className="block text-sm font-medium text-gray-700">
                                Titul za jménem
                            </label>
                            <div className="mt-1">
                                <input type="text" id="post"  {...register(prefix + "_post")}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                
                            </div>
                            </div> 
                            </>
                        }
                        <div className="sm:col-span-6 flex justify-end text-green-500 absolute right-0">
                        {degree ? ( 
                                <button type="button" onClick={() => setDegree(!degree)} className="text-sm font-medium focus:outline-none flex items-center">
                                <svg className="inline h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg> Skrýt titul

                            </button>
                        ) : (
                            <button type="button" onClick={() => setDegree(!degree)} className="text-sm font-medium focus:outline-none flex items-center ">
                            <svg className="inline h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg> Přidat titul
                            </button>
                        )}
                        </div>  
                  
               

            {prefix === 'transport' && 
              <>  
              <div className="sm:col-span-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1 relative">
                  <input id="email"  type="email"  {...register("email", { required: true })} disabled={disabled} 
                  className={"shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md " + (disabled ? "bg-gray-200" : "")} />
                  {disabled && <>
                      <button type="button" aria-label="potvrdit" onClick={() => updateAction(false)} className="absolute right-2 top-1 text-gray-600 hover:text-gray-800 p-1"><XIcon className="w-5 " /></button>
                  </>}
                  {errors?.email && 
                    <Error text="Vyplňte e-mail" />
                  }
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="tel" className="block text-sm font-medium text-gray-700">
                  Telefon
                </label>
                <div className="mt-1">
                  <input type="text"  id="tel"  {...register("tel", { required: true })}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                  {errors?.tel && 
                    <Error text="Vyplňte telefon" />
                  }
                </div>
              </div>
              </>
            }
            <div className="sm:col-span-6">
                <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                    Ulice, číslo domu  
                </label>
                <div className="mt-1">
                    <input type="text"  id="street"  {...register(prefix + "_street", { required: true })}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    {errors[prefix + '_street'] && 
                    <Error text="Vyplňte ulici" />
                    }
                </div>
                </div>

            <div className="sm:col-span-4">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    Město  
                </label>
                <div className="mt-1">
                    <input type="text"  id="city"   {...register(prefix + "_city", { required: true })}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    {errors[prefix + '_city'] && 
                    <Error text="Vyplňte město" />
                    }
                </div>
                </div>

                <div className="sm:col-span-2">
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                    PSČ
                </label>
                <div className="mt-1">
                    <input type="text" id="zip"  {...register(prefix + "_zip", { required: true })}  className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    {errors[prefix + '_zip'] && 
                    <Error text="Vyplňte PSČ" />
                    }
                </div>
                </div> 
        </div>
      </>
    )
}