import { PaperClipIcon } from '@heroicons/react/solid'
import { format  } from 'date-fns'
import { NavLink } from "react-router-dom"


export const DashboardFacilityItem = ({dashboard, cut}) => {
    return (
        <>
              <div  className="bg-white px-4 py-6 shadow sm:p-6 rounded-md my-2 relative border hover:border-gray-400 hover:bg-gray-50 " >
                    <article aria-labelledby={'dashboard-title-' + dashboard.id}>
                      <div>
                       
                        <h2 id={'dashboard-title-' + dashboard.id} className=" text-lg font-medium text-gray-900">
                          {dashboard.name}
                        </h2>
                      </div>
                      <div
                        className={"mt-2 text-sm text-gray-700 space-y-4 " + (cut ? "line-clamp-6" : " ")}
                        dangerouslySetInnerHTML={{ __html: dashboard.short_text }}
                      />
                      <div className="mt-6 flex justify-between space-x-8  w-full bottom-4 left-0 ">
                        <div className="flex space-x-6">
                        
                          
                          {dashboard?.files?.length > 0 && 
                          
                            <span className="inline-flex items-center text-sm">
                              <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                                <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                                <span className="font-medium text-gray-900">{dashboard?.files?.length}</span>
                                <span className="sr-only">přílohy</span>
                              </button>
                            </span>
                          }
                            
                          
                        </div>
                        <div className="flex space-x-3">
                          <div className="flex-shrink-0">
                            {dashboard?.author_img ? (
                              <img className="h-10 w-10 rounded-full" src={dashboard.author_img} alt="" />
                            ) : (
                              ""
                            )
                            }
                          </div>
                          <div className="min-w-0 flex-1 grid">
                            <p className="text-sm font-medium text-gray-900">
                              
                                {dashboard?.author}
                              
                            </p>
                            <p className="text-sm text-gray-500 text-right">
                              
                                <time dateTime={dashboard.datetime}>{format(new Date(dashboard.date), 'd. M. y')}</time>
                               
                            </p>
                          </div>
                          <div className="flex-shrink-0 self-center flex">
                             
                          </div>
                        </div>
                      </div>
                    </article>
                    <NavLink to={'/nastenka/spravce/' + dashboard.id} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                      </NavLink>
                  </div>
        </>
    )
}