import React from "react";
import { DashboardPost } from "./DashboardPost";
import ReactPaginate from "react-paginate";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Empty } from "../layout/Empty";

export const DashboardPosts = (props) => {
    const building = props.building;
    const myRef = useRef(null);
    const hash = parseInt(window.location.hash.substr(1));
    const navigate = useNavigate();

    const [offset, setOffset] = useState(1);
    const [perPage] = useState(10);
    const [data, setData] = useState(building?.messageboard_all);
    const [pageCount, setPageCount] = useState(0);
    const [tempData, setTempData] = useState();

    const executeScroll = () => myRef.current.scrollIntoView();

    function updateData(data) {
        if (building?.messageboard_all) {
            const slice = building?.messageboard_all.slice(
                (offset - 1) * perPage,
                (offset - 1) * perPage + perPage
            );
            setData(slice);
        }
    }
    function handlePageClick(e) {
        const selectedPage = e.selected;
        setOffset(selectedPage + 1);
        navigate("#" + (selectedPage + 1));
        executeScroll();
    }

    useEffect(() => {
        if (!isNaN(hash)) {
            setOffset(hash);
        }
        setPageCount(Math.ceil(building?.messageboard_all?.length / perPage));
        setTempData(building?.messageboard_all);
        updateData(building?.messageboard_all);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateData(tempData);
        // eslint-disable-next-line
    }, [offset]);

    return (
        <>
            <div ref={myRef}></div>

            <h3 className="text-lg text-gray-500 md:text-xl mx-auto  mb-4">
                Nástěnka objektu
            </h3>

            <section>
                <div className="   sm:grid sm:grid-cols-2 sm:gap-px ">
                    {data &&
                        data.map((message, messageIdx) => (
                            <DashboardPost key={message.id} message={message} />
                        ))}
                </div>
                {!data && <Empty />}
            </section>
            {pageCount > 1 && (
                <div className="  px-4 py-10 grid items-center justify-items-center  sm:px-6">
                    <ReactPaginate
                        previousLabel={
                            offset > 1 && (
                                <>
                                    <svg
                                        className="h-5 w-5 inline"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span className="inline-flex sm:hidden">
                                        Předchozí
                                    </span>
                                </>
                            )
                        }
                        nextLabel={
                            offset !== pageCount && (
                                <>
                                    <span className="inline-flex sm:hidden">
                                        Následující
                                    </span>
                                    <svg
                                        className="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </>
                            )
                        }
                        previousClassName={
                            offset > 1
                                ? "relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between"
                                : ""
                        }
                        previousLinkClassName={
                            offset > 1
                                ? " px-2 py-2  focus:outline-none  inline-flex items-center"
                                : ""
                        }
                        nextClassName={
                            offset !== pageCount
                                ? "relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
                                : ""
                        }
                        nextLinkClassName={
                            offset !== pageCount
                                ? "px-2 py-2 focus:outline-none inline-flex items-center"
                                : ""
                        }
                        breakLabel={"..."}
                        breakClassName={
                            "relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                        }
                        pageCount={pageCount}
                        initialPage={!isNaN(hash) && hash > 1 && hash - 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        disableInitialCallback={true}
                        onPageChange={handlePageClick}
                        containerClassName={
                            "relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "
                        }
                        subContainerClassName={""}
                        pageClassName={
                            "hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 "
                        }
                        pageLinkClassName={
                            "px-4 py-2 text-gray-700 focus:outline-none"
                        }
                        activeClassName={"bg-green-200 hover:bg-green-300 "}
                    />
                </div>
            )}
        </>
    );
};
